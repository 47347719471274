import React from "react";
import { useNavigate } from "react-router-dom";
import "../assets/css/Footer.css";

const Footer = () => {
  const navigate = useNavigate(); // Hook para redirigir

  const handleLoginClick = () => {
    navigate("/login");
  };

  return (
    <footer className="footer bg-dark text-white">
      <div className="container py-3">
        <div className="row align-items-center">
          <div className="col text-center">
            <span>
              © {new Date().getFullYear()} Gustavo Argandoña - All rights reserved.
            </span>
          </div>
          <div className="col-auto">
            <button className="login-button" onClick={handleLoginClick}>
              Login
            </button>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
