import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

const UploadImage = () => {
  const [imageBase64, setImageBase64] = useState('');
  const [credits, setCredits] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [date, setDate] = useState('');
  const [type, setType] = useState('portrait');
  const [token, setToken] = useState('');

  useEffect(() => {
    const savedToken = localStorage.getItem('authToken');
    if (savedToken) {
      setToken(savedToken);
    }
  }, []);

  const handleImageUpload = async (e) => {
    e.preventDefault();
    try {
      await axios.post(
        'http://localhost:3000/api/images/upload',
        {
          imageBase64,
          credits,
          subtitle,
          date,
          type,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      alert('Image uploaded successfully!');
    } catch (err) {
      alert('Failed to upload image');
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setImageBase64(reader.result.split(',')[1]); // remove data:image/jpeg;base64,
    };
    reader.readAsDataURL(file);
  };

  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <div className="card shadow p-4" style={{ width: '500px' }}>
        <h2 className="text-center mb-4">Upload Image</h2>
        <form onSubmit={handleImageUpload}>
          <div className="form-group mb-3">
            <label htmlFor="file" className="form-label">Image:</label>
            <input
              type="file"
              id="file"
              onChange={handleImageChange}
              className="form-control"
              required
            />
          </div>
          <div className="form-group mb-3">
            <label htmlFor="credits" className="form-label">Credits:</label>
            <input
              type="text"
              id="credits"
              value={credits}
              onChange={(e) => setCredits(e.target.value)}
              className="form-control"
              placeholder="Enter image credits"
              required
            />
          </div>
          <div className="form-group mb-3">
            <label htmlFor="subtitle" className="form-label">Subtitle:</label>
            <input
              type="text"
              id="subtitle"
              value={subtitle}
              onChange={(e) => setSubtitle(e.target.value)}
              className="form-control"
              placeholder="Enter image subtitle"
              required
            />
          </div>
          <div className="form-group mb-3">
            <label htmlFor="date" className="form-label">Date:</label>
            <input
              type="date"
              id="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              className="form-control"
              required
            />
          </div>
          <div className="form-group mb-4">
            <label htmlFor="type" className="form-label">Type:</label>
            <select
              id="type"
              value={type}
              onChange={(e) => setType(e.target.value)}
              className="form-select"
            >
              <option value="portrait">Portrait</option>
              <option value="onstage">On Stage</option>
            </select>
          </div>
          <button type="submit" className="btn btn-primary w-100">Upload</button>
        </form>
      </div>
    </div>
  );
};

export default UploadImage;
