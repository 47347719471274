import React from 'react';
import { Link } from 'react-router-dom';
import '../assets/css/Navbar.css';
// import logo from '../assets/img/logo.jpg';

const Navbar = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <div className="container">
        {/* <Link className="navbar-brand d-flex align-items-center" to="/">
          <img src={logo} height="55px" alt="Gustavo Argandoña Logo" className="navbar-logo me-2" /> 
        </Link> */}
        <div className="navbar-collapse show" id="navbarNav">
          <ul className="navbar-nav ms-auto d-flex align-items-center">
            <li className="nav-item">
              <Link className="nav-link" to="/">Home</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/about-me">About Me</Link>
            </li>
            <li className="nav-item dropdown">
              <Link className="nav-link dropdown-toggle" to="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Multimedia
              </Link>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li><Link className="dropdown-item" to="/gallery/portraits">Portraits</Link></li>
                <li><Link className="dropdown-item" to="/gallery/on-stage">On Stage</Link></li>
                <li><Link className="dropdown-item" to="/gallery/my-beginnings">My Beginnings</Link></li>
              </ul>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/contact">Contact</Link>
            </li>
            {/* Redes sociales */}
            <li className="nav-item d-flex align-items-center ms-2">
              <a className="nav-link social-icon" href="https://youtube.com/@gustavo_argandona?si=NP6wOifrkTKcRBGq" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-youtube"></i>
              </a>
              <a className="nav-link social-icon" href="https://www.instagram.com/gustavo_argandona/profilecard/?igsh=MXNoZHJyMzJ5bTdzbg%3D%3D" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-instagram"></i>
              </a>
              <a className="nav-link social-icon" href="https://www.facebook.com/frankogustavot" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-facebook"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
