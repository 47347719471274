import React from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar';
import Banner from './components/Banner';
import AboutMe from './components/AboutMe';
import Contact from './components/Contact';
import Portraits from './components/Portraits';
import OnStage from './components/OnStage';
import MyBeginnings from './components/MyBeginnings';
import Login from './components/Login';
import UploadImage from './components/Upload';
import Footer from './components/Footer';

function App() {
  return (
    <Router>
      <Navbar />
      <div className="container-fluid p-0">
        <Routes>
          <Route path="/" element={<Banner />} />
          <Route path="/about-me" element={<AboutMe />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/gallery/portraits" element={<Portraits />} /> {/* Ruta para Portraits */}
          <Route path="/gallery/on-stage" element={<OnStage />} /> {/* Ruta para On Stage */}
          <Route path="/gallery/my-beginnings" element={<MyBeginnings />} /> {/* Ruta para My Beginnings */}
          <Route path="/login" element={<Login />} /> {/* Ruta oculta para iniciar sesión */}
          <Route path="/upload" element={<UploadImage />} /> {/* Ruta oculta para subir imágenes */}
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
