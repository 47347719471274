import React from 'react';
import '../assets/css/Banner.css';
import portraitDesktop from '../assets/img/portrait1.png';
import portraitMobile from '../assets/img/portrait3mobile.png';

const Banner = () => {
  return (
    <div className="banner">
      <img src={portraitDesktop} alt="Banner" className="banner-image desktop" />
      <img src={portraitMobile} alt="Banner" className="banner-image mobile" />
      {/* <div className="banner-content">
        <h1>Gustavo Argandoña</h1>
        <p>Countertenor</p>
      </div> */}
    </div>
  );
};

export default Banner;
