import React, { useState } from "react";
import "../assets/css/Gallery.css";

// Lista de imágenes estáticas
const imageList = [
  "IMG_8329.JPG",
  "IMG_8338.JPG",
  "IMG_8343.JPG",
  "IMG_8344.JPG",
  "IMG_8345.JPG",
  "IMG_8346.JPG",
  "IMG_8347.JPG",
  "IMG_8348.JPG",
  "IMG_8358.JPG",
  "IMG_8359.JPG",
  "IMG_8390.PNG",
  "IMG_8927.JPG",
  "Premios festival Freirina Live rock.JPG",
];

const MyBeginnings = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(image); // Abrir visor con la imagen seleccionada
  };

  const closeImageViewer = () => {
    setSelectedImage(null); // Cerrar visor
  };

  return (
    <div className="gallery-container">
      <h2>My Beginnings</h2>

      {/* Contenedor del video */}
      <div className="video-placeholder" >
      <video
        width="100%"
        controls
        poster={require("../assets/img/mei-inizi/mybeginnings.jpg")}
      >
          <source
            src={require("../assets/img/mei-inizi/video-output-198BDABE-1D5F-40DC-A2B8-87C3E0EE299F.mp4")}
            type="video/mp4"
          />
          
          Your browser does not support the video tag.
        </video>
      </div>

      {/* Grilla de imágenes */}
      <div className="image-grid">
        {imageList.map((image) => (
          <div
            key={image}
            className="image-thumbnail-portrait"
            onClick={() => handleImageClick(image)}
          >
            <img
              src={require(`../assets/img/mei-inizi/${image}`)}
              alt={image}
              className="thumbnail-img-portrait"
            />
          </div>
        ))}
      </div>

      {/* Visor de imagen en pantalla completa */}
      {selectedImage && (
        <div className="image-viewer" onClick={closeImageViewer}>
          <span className="close-button">&times;</span>
          <img
            src={require(`../assets/img/mei-inizi/${selectedImage}`)}
            alt="Selected"
            className="full-screen-image"
          />
        </div>
      )}
    </div>
  );
};

export default MyBeginnings;
