import React from "react";
import "../assets/css/Contact.css";
import portrait from "../assets/img/contact.jpg";

const Contact = () => {
  return (
    <div className="contact-page">
      <div className="contact-container">
        {/* Imagen */}
        <div className="contact-image-container">
          <img
            src={portrait}
            alt="Gustavo Argandoña"
            className="contact-image"
          />
        </div>

        {/* Información de Contacto */}
        <div className="contact-info-container">
          <h2>Contact</h2>
          <ul className="list-unstyled">
            <li>
              <i className="fas fa-envelope"></i>
              gustavo.contratenor@gmail.com
            </li>
            <li>
              <i className="fas fa-phone-alt"></i>
              &nbsp;+393518018698
            </li>
            <li>
              <i className="fas fa-map-marker-alt"></i>
              &nbsp;Turin, Italy
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Contact;
