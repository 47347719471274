import React, { useState } from "react";
import "../assets/css/PortraitsGallery.css";

// Lista de imágenes estáticas con créditos en blanco
const imageList = [
  { src: "3 foto portraits.jpg", credit: "© Mattia Comandone" },
  { src: "1 foto en portraits.jpg", credit: "© Mattia Comandone" },
  { src: "IMG_9122.jpg", credit: "© Andrea Gelain Photography" },
  { src: "IMG_9255.jpg", credit: "© Andrea Gelain Photography" },
  { src: "IMG_9256.jpg", credit: "© Andrea Gelain Photography" },
  { src: "IMG_9257.jpg", credit: "© Andrea Gelain Photography" },
  { src: "2 foto portraits.jpg", credit: "© Mattia Comandone" },
  { src: "4 foto portraits.jpg", credit: "© Mattia Comandone" },
  { src: "FF4C1751-DAD3-473E-A857-70E749F7446B.jpg", credit: "" },
  { src: "5 foto portraits.jpg", credit: "© Mattia Comandone" },
  { src: "Opcional 1.jpg", credit: "" },
  { src: "Opcional 2.jpg", credit: "" },
  { src: "Opcional 3.jpg", credit: "" },
  { src: "Opcional 4.jpg", credit: "" },
];

const Portraits = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const closeImageViewer = () => {
    setSelectedImage(null);
  };

  return (
    <div className="gallery-container">
      <h2>Portraits</h2>

      {/* Grilla de imágenes */}
      <div className="image-grid">
        {imageList.map((image) => (
          <div
            key={image.src}
            className="image-thumbnail-portrait"
            onClick={() => handleImageClick(image)}
          >
            <img
              src={require(`../assets/img/portraits/${image.src}`)}
              alt={image.src}
              className="thumbnail-img-portrait"
            />
            <div className="watermark">{image.credit}</div>
          </div>
        ))}
      </div>

      {/* Visor de imagen en pantalla completa */}
      {selectedImage && (
        <div className="image-viewer">
          <span className="close-button" onClick={closeImageViewer}>
            &times;
          </span>
          <img
            src={require(`../assets/img/portraits/${selectedImage.src}`)}
            alt="Selected"
            className="full-screen-image"
          />
          {selectedImage.credit && (
            <div className="watermark-fullscreen">{selectedImage.credit}</div>
          )}
        </div>
      )}
    </div>
  );
};

export default Portraits;
